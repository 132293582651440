import { filter, find, map, merge } from 'lodash';
import { applyDiscountToValue, sumByWithError, calcCategoryValuesByHighlightBindingness, CalculationMode, calcCartValuesByHighlightBindingness, calcCategoryValuesByHighlightBindingnessFromItem, getVatValue } from '.';
import { inputFieldPaymentInterval } from '../constants';
import { calcWithRawFormula, getItemFormulaWithScales } from '../mentions/utils';
import { roundUpValue, variablesFromCategory, round } from './calcUtils';
import { priceKeys } from './constants';

export const applyVatToValue = ({ value, vat }) => {
  if (!Number.isFinite(vat) || !Number.isFinite(value)) return value;
  const vatValue = +(value * vat).toFixed(2);
  return value + vatValue;
};

const calcMonthlyAdvanceOfCategoryTotal = (props) => {
  if (Number.isFinite(props)) return props;
  const monthlyDiscounted = props.monthly.discountedValue;
  const yearlyDiscounted = props.yearly.discountedValue;
  if (Number.isFinite(monthlyDiscounted) && Number.isFinite(yearlyDiscounted)) return (monthlyDiscounted ?? 0) + round((yearlyDiscounted ?? 0) / 12);
  return null;
};

export const calcItem = (props) => {
  const { documents, document, variables, depth = 0, calculated = {}, calcCategoryTotalById, allCategoryIds = [] } = props;
  let categoryTotals = {};
  let value = 0;
  if (calculated[document._id] !== undefined) return { value: calculated[document._id], categoryTotals };
  if (document.calculationMode === CalculationMode.ON_ACTUAL_COST) {
    calculated[document._id] = value;
    return { value, categoryTotals };
  }
  if (Number.isFinite(document.customPrice)) {
    value = document.customPrice;

    calculated[document._id] = value;
    return { value, categoryTotals };
  }
  let pricingFormula;
  if (document.options) pricingFormula = find(document.options, { _id: variables[document._id] })?.value;
  else pricingFormula = document.pricingFormula;
  if (pricingFormula) {
    if (depth === 5) throw new Error('circular dep');
    const documentsUsedInFormula = documents.filter((otherItem) => otherItem._id !== document._id && pricingFormula.includes(otherItem._id));
    const categoryIdsUsedInFormula = allCategoryIds.filter((categoryId) => categoryId !== document.categoryId && pricingFormula.includes(categoryId));
    categoryTotals = merge(...categoryIdsUsedInFormula.map((id) => ({ [id]: calcMonthlyAdvanceOfCategoryTotal(calcCategoryTotalById(id)) })));
    // if (find(itemsUsedInFormula, (i) => itemIdsUsed.includes(i._id))) throw new Error('circular dep');
    const calculatedItems = documentsUsedInFormula.map((documentsUsed) => [documentsUsed._id, calcItem({ ...props, document: documentsUsed, depth: depth + 1, calculated }).value]);
    let currentPricingFormula = getItemFormulaWithScales({ pricingFormula, scales: document.scales, graduatedScaleMode: document.graduatedScaleMode });
    if (calculatedItems.length)
      calculatedItems.forEach(([itemId, calculatedItemValue]) => {
        currentPricingFormula = currentPricingFormula.replace(new RegExp(`@{{${itemId}}}`, 'ig'), calculatedItemValue);
      });
    value = calcWithRawFormula({ variables: { ...variables, ...categoryTotals }, expression: currentPricingFormula, minPrice: document.minPrice });
  }
  value = roundUpValue({ value, roundPrice: document?.roundPrice });
  // eslint-disable-next-line no-param-reassign
  calculated[document._id] = value;
  return { value, categoryTotals };
};

const formatCategoryTotal = ({ value, discountedValue, vat }) => {
  const VATData = {
    [priceKeys.VAT]: 0,
    [priceKeys.discountedVAT]: 0,
    [priceKeys.valueWithVAT]: value,
    [priceKeys.discountedValueWithVAT]: discountedValue,
  };
  if (vat) {
    const VAT = getVatValue({ value, vat });
    const discountedVAT = getVatValue({ value: discountedValue, vat });
    VATData[priceKeys.VAT] = VAT;
    VATData[priceKeys.discountedVAT] = discountedVAT;
    VATData[priceKeys.valueWithVAT] = (VAT || 0) + value;
    VATData[priceKeys.discountedValueWithVAT] = (discountedVAT || 0) + discountedValue;
  }
  return {
    [priceKeys.value]: value,
    [priceKeys.discountedValue]: discountedValue,
    ...VATData,
  };
};

export const calcCategoryTotal = ({ cartCategory, inputFields, documents, discount, throwErrors, vat, variables_a, calcCategoryTotalById, allCategoryIds }) => {
  const variables = variablesFromCategory({ inputFields, cartCategory, variables_a });
  const itemValues = map(cartCategory.itemIds, (_id) => {
    const item = find(documents, { _id });
    if (!item) return null;
    let value = 0;
    try {
      value = calcItem({ documents, document: item, variables, calcCategoryTotalById, allCategoryIds }).value;
      if (!Number.isFinite(value)) value = 'error';
    } catch (e) {
      if (!e.message.includes('is empty')) throw e;
      if (throwErrors) value = 'error';
    }
    return { paymentInterval: item.paymentInterval, value, highlightBindingness: item.highlightBindingness };
  });
  const categoryTotalPrices = Object.values(inputFieldPaymentInterval).reduce((prev, paymentInterval) => {
    const paymentIntervalValues = filter(itemValues, { paymentInterval });
    const value = sumByWithError(paymentIntervalValues, (v) => v.value || 0);
    const discountedValue = applyDiscountToValue({
      value,
      discount,
    });
    return {
      ...prev,
      [paymentInterval]: {
        ...formatCategoryTotal({ value, discountedValue, vat }),
        highlightBindingnessValues: calcCategoryValuesByHighlightBindingness({ paymentIntervalValues, discount, vat }),
      },
    };
  }, {});
  return categoryTotalPrices;
};

const calcCategoryTotalWithDiscount = ({ cartCategory, discounts, vat, inputFields, documents, throwErrors, variables_a, calcCategoryTotalById, allCategoryIds }) => {
  const discount = find(discounts, { _id: cartCategory.discountId });
  return calcCategoryTotal({ cartCategory, inputFields, documents, discount, vat, throwErrors, calcCategoryTotalById, variables_a, allCategoryIds });
};

// eslint-disable-next-line no-unused-vars
const roundPrice = (price, roundingOption) => {
  return price;
};

const calculateMonthlyAdvancePrice = ({ yearlyPriceWithoutVAT, monthlyPriceWithoutVat, roundingOption, vat }) => {
  const monthlyPartOfYearlyPrice = yearlyPriceWithoutVAT / 12;
  let monthlyAdvancePriceWithoutVAT = monthlyPartOfYearlyPrice + monthlyPriceWithoutVat;
  monthlyAdvancePriceWithoutVAT = roundPrice(monthlyAdvancePriceWithoutVAT, roundingOption);
  const monthlyAdvancePriceVAT = monthlyAdvancePriceWithoutVAT * vat;
  const monthlyAdvancePriceWithVat = monthlyAdvancePriceWithoutVAT + monthlyAdvancePriceVAT;
  return {
    value: monthlyAdvancePriceWithoutVAT,
    valueWithVAT: monthlyAdvancePriceWithVat,
    VAT: monthlyAdvancePriceVAT,
  };
};

const calculateMonthlyAdvanceWithoutDiscounts = ({ yearly, monthly, roundingOption, vat }) => {
  return calculateMonthlyAdvancePrice({ yearlyPriceWithoutVAT: yearly.value, monthlyPriceWithoutVat: monthly.value, roundingOption, vat });
};

const calculateMonthlyAdvanceWithDiscounts = ({ yearly, monthly, roundingOption, vat }) => {
  return calculateMonthlyAdvancePrice({ yearlyPriceWithoutVAT: yearly.discountedValue, monthlyPriceWithoutVat: monthly.discountedValue, roundingOption, vat });
};

const roundToTwoDigits = (price) => {
  return Math.round(price * 100) / 100;
};

const calculateHighlightBindingnessValueForMonthlyAdvance = ({ yearlyValue, monthlyValue, vat }) => {
  const withoutVAT = yearlyValue / 12 + monthlyValue;
  // withoutVAT = roundPrice(withoutVAT, roundingOption);
  const VAT = withoutVAT * vat;
  return {
    discountedValue: roundToTwoDigits(withoutVAT),
    discountedVAT: roundToTwoDigits(VAT),
  };
};

const calculateHighlightBindingnessValuesForMonthlyAdvance = ({ yearlyHighlightBindingnessValues, monthlyHighlightBindingnessValues, roundingOption, vat }) => {
  const dynamic = calculateHighlightBindingnessValueForMonthlyAdvance({ yearlyValue: yearlyHighlightBindingnessValues.dynamic.discountedValue, monthlyValue: monthlyHighlightBindingnessValues.dynamic.discountedValue, roundingOption, vat });
  const estimated = calculateHighlightBindingnessValueForMonthlyAdvance({ yearlyValue: yearlyHighlightBindingnessValues.estimated.discountedValue, monthlyValue: monthlyHighlightBindingnessValues.estimated.discountedValue, roundingOption, vat });
  const fixPriced = calculateHighlightBindingnessValueForMonthlyAdvance({ yearlyValue: yearlyHighlightBindingnessValues.fixPriced.discountedValue, monthlyValue: monthlyHighlightBindingnessValues.fixPriced.discountedValue, roundingOption, vat });
  return {
    dynamic,
    estimated,
    fixPriced,
  };
};

const calculateMonthlyAdvance = ({ yearly, monthly, roundingOption = 'noRound', vat }) => {
  const monthlyAdvanceWithoutDiscounts = calculateMonthlyAdvanceWithoutDiscounts({ yearly, monthly, roundingOption, vat });
  const monthlyAdvanceWithDiscounts = calculateMonthlyAdvanceWithDiscounts({ yearly, monthly, roundingOption, vat });
  const highlightBindingnessValues = calculateHighlightBindingnessValuesForMonthlyAdvance({ yearlyHighlightBindingnessValues: yearly.highlightBindingnessValues, monthlyHighlightBindingnessValues: monthly.highlightBindingnessValues, roundingOption, vat });
  return {
    discountedVAT: roundToTwoDigits(monthlyAdvanceWithDiscounts.VAT),
    discountedValue: roundToTwoDigits(monthlyAdvanceWithDiscounts.value),
    discountedValueWithVAT: roundToTwoDigits(monthlyAdvanceWithDiscounts.valueWithVAT),
    highlightBindingnessValues,
    VAT: roundToTwoDigits(monthlyAdvanceWithoutDiscounts.VAT),
    value: roundToTwoDigits(monthlyAdvanceWithoutDiscounts.value),
    valueWithVAT: roundToTwoDigits(monthlyAdvanceWithoutDiscounts.valueWithVAT),
  };
};

/**
 * Calculates the total of a cart using all dependend data
 * @param {Object} cart - The category object of a cart
 * @param {Object} categories - The categories object
 * @param {Object} inputFields - The inputFields object
 * @param {Object} documents - The documents object
 * @param {Object} variables_a - The variables object
 * @param {Object} discounts - The discounts object
 * @param {number} vat - The VAT value
 * @param {boolean} throwErrors - If true, throws errors if there are any
 */
export const calcCartTotal = ({ cart, categories, inputFields, documents, variables_a, discounts, vat, throwErrors } = {}) => {
  const allCategoryIds = categories.map((c) => c._id);
  const calcCategoryTotalById = (_id) => {
    const cartCategory = find(cart, { _id });
    if (!cartCategory) return 0;
    return calcCategoryTotalWithDiscount({ cartCategory, discounts, vat, variables_a, inputFields, documents, throwErrors, calcCategoryTotalById, allCategoryIds });
  };

  const categoriesTotal = map(cart, (cartCategory) => calcCategoryTotalById(cartCategory._id));

  const total = Object.values(inputFieldPaymentInterval).reduce((prev, paymentInterval) => {
    const values = Object.keys(priceKeys).reduce((acc, key) => ({ ...acc, [key]: sumByWithError(categoriesTotal, (v) => v[paymentInterval]?.[key] || 0) }), {});
    return { ...prev, [paymentInterval]: { ...values, highlightBindingnessValues: calcCartValuesByHighlightBindingness({ categoriesTotal, paymentInterval }) } };
  }, {});
  const monthlyAdvance = calculateMonthlyAdvance({ yearly: total.yearly, monthly: total.monthly, vat });
  total.monthlyAdvance = monthlyAdvance;
  return total;
};

/**
 * Calculates the total of a category from the category of the cart object
 * @param {Object} cartCategory - The category object of a cart
 * @param {number} vat - The VAT value
 */
export const calcCategoryTotalFromCategory = (cartCategory, vat) => {
  const categoryTotalPrices = Object.values(inputFieldPaymentInterval).reduce((prev, paymentInterval) => {
    const itemsWithThisPaymentInterval = filter(cartCategory.items, { paymentInterval });
    const value = sumByWithError(itemsWithThisPaymentInterval, (item) => {
      if (Number.isFinite(item.customPrice)) {
        return item.customPrice;
      }
      return item.value || 0;
    });
    const discountedValue = sumByWithError(itemsWithThisPaymentInterval, (item) => {
      if (Number.isFinite(item.customPrice)) {
        const discount = item.discountedValue / item.value;
        return item.customPrice * discount;
      }
      return item.discountedValue || 0;
    });
    return {
      ...prev,
      [paymentInterval]: {
        ...formatCategoryTotal({ value, discountedValue, vat }),
        highlightBindingnessValues: calcCategoryValuesByHighlightBindingnessFromItem(itemsWithThisPaymentInterval, vat),
      },
    };
  }, {});
  return categoryTotalPrices;
};

/**
 * Calculates the value for one specific payment interval
 * @param {Object} categoriesTotal - The categories total object
 * @param {string} paymentInterval - The payment interval
 * @param {string} key - The key of the payment interval
 */
function sumValuesByKey(categoriesTotal, paymentInterval, key) {
  return sumByWithError(categoriesTotal, (v) => v[paymentInterval]?.[key] || 0);
}

/**
 * Calculates the values for all keys for all payment intervals
 * @param {Object} categoriesTotal - The categories total object
 * @param {string} paymentInterval - The payment interval
 */
function calculateValuesForAllKeys(categoriesTotal, paymentInterval) {
  return Object.keys(priceKeys).reduce(
    (acc, key) => ({
      ...acc,
      [key]: sumValuesByKey(categoriesTotal, paymentInterval, key),
    }),
    {},
  );
}

/**
 * Computes the payment interval details
 * @param {Object} categoriesTotal - The categories total object
 * @param {string} paymentInterval - The payment interval
 */
function computePaymentIntervalDetails(categoriesTotal, paymentInterval) {
  const values = calculateValuesForAllKeys(categoriesTotal, paymentInterval);
  const highlightBindingnessValues = calcCartValuesByHighlightBindingness({ categoriesTotal, paymentInterval });
  return { ...values, highlightBindingnessValues };
}

/**
 * Calculates the total from all category totals
 * @param {Object} categoriesTotal - The categories total object
 */
function calculateTotalFromCategoriesTotal(categoriesTotal) {
  return Object.values(inputFieldPaymentInterval).reduce((prev, paymentInterval) => {
    return {
      ...prev,
      [paymentInterval]: computePaymentIntervalDetails(categoriesTotal, paymentInterval),
    };
  }, {});
}

/**
 *  Calculates the total of the cart from the cart object
 * @param {Object} cart - The cart object containing the categories and items
 * @param {number} vat - The VAT value
 */
export const calcCartTotalFromCart = (cart, vat) => {
  const categoriesTotal = cart.map((cartCategory) => {
    return calcCategoryTotalFromCategory(cartCategory, vat);
  });

  const total = calculateTotalFromCategoriesTotal(categoriesTotal);
  const monthlyAdvance = calculateMonthlyAdvance({ yearly: total.yearly, monthly: total.monthly, vat });
  total.monthlyAdvance = monthlyAdvance;
  return total;
};
