import { map, merge, round as roundLodash, get, groupBy, sum } from 'lodash';
import { format, isValid } from 'date-fns';
import { numberToLocaleString } from '../utils';
import { inputFieldPaymentInterval, nbsp } from '../constants';
import { applyDiscountToValue, HighlightBindingness, CalculationMode } from '.';
// export const getFormulaName = (str) => upperFirst(camelCase(str));
// export const arrayIdNameToObject = (arr) => arr.reduce((prev, { _id, name }) => ({ ...prev, [_id]: name }), {});

export const inputFieldsToVariables = (inputFields) => merge(...map(inputFields, (inputField) => map(inputField.options, (option) => ({ [option._id]: option.value }))).flat());
export const variablesFromCategory = ({ cartCategory, inputFields, variables_a }) => {
  const a = cartCategory.inputFields?.reduce(
    (prev, { _id, value, defaultValue } = {}) => ({
      ...prev,
      [_id]: value ?? defaultValue,
    }),
    {},
  );
  const b = inputFieldsToVariables(inputFields);
  const c = merge(...(variables_a?.map?.(({ _id, value }) => ({ [_id]: value })) || [{}]));
  return { ...a, ...b, ...c };
};

export const variablesFromCart = ({ cart, inputFields }) => {
  return merge(...cart.map((cartCategory) => variablesFromCategory({ cartCategory, inputFields })));
};

export const roundUpValue = ({ value, roundPrice }) => {
  if (typeof roundPrice?.roundUpTo === 'number' && !Number.isNaN(roundPrice?.roundUpTo)) {
    if (value < 0 && -roundPrice.roundUpTo < value) return -roundPrice.roundUpTo;
    if (value > 0 && roundPrice.roundUpTo > value) return roundPrice.roundUpTo;
    return value;
  }
  if (typeof roundPrice?.roundUpToMultiple === 'number' && !Number.isNaN(roundPrice?.roundUpToMultiple)) {
    const roundUp = value < 0 ? Math.floor : Math.ceil;
    return roundUp(value / roundPrice.roundUpToMultiple) * roundPrice.roundUpToMultiple;
  }
  return value;
};

export const customFormatDate = (_date, _format) => {
  const date = new Date(_date);
  if (isValid(date)) {
    return format(date, _format);
  }
  return '';
};

export const round = (value) => {
  return roundLodash(roundLodash(value, 8), 2);
};

export const getVatValue = ({ value, vat }) => {
  if (!Number.isFinite(vat) || !Number.isFinite(value)) return null;
  const vatValue = +(value * vat).toFixed(2);
  return vatValue;
};

export const formatCurrency = (number = 0, options) => {
  return `${numberToLocaleString(round(number), 'de-DE', options)}${nbsp}€`;
};

export const formatFixedValue = ({ paymentInterval = inputFieldPaymentInterval.monthly, monthly = {}, yearly = {} } = {}) => {
  const keys = Object.keys(monthly);
  const isMonthlyPaymentInterval = paymentInterval === inputFieldPaymentInterval.monthly;
  function calculate({ monthly: _monthly, yearly: _yearly, propertyName }) {
    const monthlyValue = get(_monthly, propertyName) || 0;
    const yearlyValue = get(_yearly, propertyName) || 0;
    if (typeof monthlyValue === 'object' && monthlyValue !== null) return Object.keys(monthlyValue).reduce((acc, name) => ({ ...acc, [name]: calculate({ monthly: monthlyValue, yearly: yearlyValue, propertyName: name }) }), {});
    const target = isMonthlyPaymentInterval ? monthlyValue : yearlyValue;
    const fixedValue = isMonthlyPaymentInterval ? yearlyValue / 12 : monthlyValue * 12;
    return Number.isFinite(target + fixedValue) ? target + fixedValue : 0;
  }
  return keys.reduce(
    (acc, name) => ({
      ...acc,
      [name]: calculate({ monthly, yearly, propertyName: name }),
    }),
    {},
  );
};

export const sumByWithError = (e, fn) => {
  const values = map(e, fn);
  return values.includes('error') ? 'error' : sum(values);
};

const HighlightBindingnessNames = Object.values(HighlightBindingness).filter((highlightBindingness) => highlightBindingness !== HighlightBindingness.NO_HIGHLIGHT);

/**
 * Calculates and groups financial values by highlight bindingness from an array of payment interval values.
 *
 * @param {Object[]} paymentIntervalValues - paymentIntervalValues An array of objects representing payment intervals. Each object is expected
 * to have a 'highlightBindingness' property and a 'discountedValue' property.
 * @param {number} vat - A numerical value representing the VAT percentage to be applied
 */
export const calcCategoryValuesByHighlightBindingnessFromItem = (paymentIntervalValues, vat) => {
  const groupedValues = groupBy(paymentIntervalValues, 'highlightBindingness');
  return HighlightBindingnessNames.reduce((highlightBindingnessValues, highlightBindingness) => {
    const discountedValue = sumByWithError(groupedValues[highlightBindingness], (item) => {
      if (Number.isFinite(item.customPrice)) {
        const discount = item.discountedValue / item.value;
        return item.customPrice * discount;
      }
      return item.discountedValue;
    });
    const discountedVAT = vat ? getVatValue({ value: discountedValue, vat }) ?? 0 : 0;

    return {
      ...highlightBindingnessValues,
      [highlightBindingness]: {
        discountedValue,
        discountedVAT,
      },
    };
  }, {});
};

export const calcCategoryValuesByHighlightBindingness = ({ paymentIntervalValues, discount, vat }) => {
  const groupedValues = groupBy(paymentIntervalValues, 'highlightBindingness');
  return HighlightBindingnessNames.reduce((highlightBindingnessValues, highlightBindingness) => {
    const value = sumByWithError(groupedValues[highlightBindingness], (v) => v.value);
    const vatValue = vat ? getVatValue({ value, vat }) ?? 0 : 0;
    const discountedValue = applyDiscountToValue({
      value,
      discount,
    });
    const discountedVAT = applyDiscountToValue({
      value: vatValue,
      discount,
    });
    return {
      ...highlightBindingnessValues,
      [highlightBindingness]: {
        discountedValue,
        discountedVAT,
      },
    };
  }, {});
};

export const calcCartValuesByHighlightBindingness = ({ categoriesTotal, paymentInterval }) => {
  return HighlightBindingnessNames.reduce((highlightBindingnessValues, highlightBindingness) => {
    return {
      ...highlightBindingnessValues,
      [highlightBindingness]: ['discountedValue', 'discountedVAT'].reduce((acc, fieldName) => ({ ...acc, [fieldName]: sumByWithError(categoriesTotal, (v) => v[paymentInterval].highlightBindingnessValues[highlightBindingness][fieldName]) }), {}),
    };
  }, {});
};

const fixedPaymentIntervals = [inputFieldPaymentInterval.monthly, inputFieldPaymentInterval.yearly];

export const groupedItemsForNotes = ({ items = [], paymentInterval, mergeFixed, isCalculateHighlightBindingness } = {}) => {
  const isFixedPaymentIntervals = mergeFixed && fixedPaymentIntervals.includes(paymentInterval);
  const filteredItems = paymentInterval ? items.filter((item) => (isFixedPaymentIntervals ? fixedPaymentIntervals.includes(item.paymentInterval) : item.paymentInterval === paymentInterval)) : items;
  let allPaymentIntervalItems;
  if (isFixedPaymentIntervals) allPaymentIntervalItems = filteredItems;
  else if (paymentInterval) allPaymentIntervalItems = items.filter((item) => item.paymentInterval === paymentInterval);
  else allPaymentIntervalItems = items;
  return {
    allSelectedItems: allPaymentIntervalItems.length,
    ...filteredItems.reduce((acc, item) => {
      if (item.calculationMode === CalculationMode.ON_ACTUAL_COST) acc[item.calculationMode] = 1 + (acc[item.calculationMode] || 0);
      else if (isCalculateHighlightBindingness && HighlightBindingnessNames.includes(item.highlightBindingness)) {
        acc[item.highlightBindingness] = 1 + (acc[item.highlightBindingness] || 0);
      }
      return acc;
    }, {}),
  };
};
