/* eslint-disable no-shadow */
import React from 'react';
import styled from 'styled-components';
import { get, slice } from 'lodash';

const avoidPageBreak = ({ nonBreakingHeight = 50 } = {}) => {
  // https://stackoverflow.com/questions/9238868/how-do-i-avoid-a-page-break-immediately-after-a-heading
  return `page-break-inside: avoid;
  ::after {
    content: '';
    display: block;
    height: ${nonBreakingHeight}px;
    margin-bottom: -${nonBreakingHeight}px;
  }`;
};

const avoidPageBreakDefaultZero = ({ nonBreakingHeight = 0 } = {}) => avoidPageBreak({ nonBreakingHeight });

const getProp = (path, ifTrue, ifFalse) => (props) => {
  if (ifTrue) {
    return get(props, `theme.${path}`) ? ifTrue : ifFalse;
  }
  return get(props, `theme.${path}`) ?? '';
};

const listStyles = (props) => {
  return ` 
    font-family: ${getProp('textBlock.fontFamily')(props)};
    color: ${getProp('textBlock.color')(props)};
    font-size: ${getProp('textBlock.fontSize')(props)};
    line-height: ${getProp('textBlock.lineHeight')(props)};
    margin-bottom: ${getProp('textBlock.marginBottom')(props)};
    margin-top: ${getProp('textBlock.marginTop')(props)};
    margin-left: 16px;
  `;
};
const addSpaceBetweenLi = ({ spaceBetweenLi = false, ...props }) => {
  return spaceBetweenLi
    ? `
    ul, ol{
      margin-bottom: ${getProp('textBlock.marginBottom')(props)} !important;
      margin-top: ${getProp('textBlock.marginTop')(props)} !important;
    }
    > li {
      margin-bottom: ${getProp('textBlock.marginBottom')(props)};
      margin-top: ${getProp('textBlock.marginTop')(props)};
      text-indent: 0px !important;
    }
    > li > *:first-child {
      margin-left: 0 !important;
    }
    > li:last-child {
      margin-bottom: 0px;
    }
    > li:first-child {
      margin-top: 0px;
    }
  `
    : '';
};

export const checkedLi = ({ theme }) => {
  const primaryColor = getProp('main.primaryColor')({ theme }) || '';
  const SVGPrimaryColor = primaryColor.replace('#', '%23');
  return `
    background: url('data:image/svg+xml; utf8,<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path  style="margin: auto; margin-left: 10px;" fill="${SVGPrimaryColor}" d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path></svg>') no-repeat left 2px;
    padding-left: 20px;
    list-style: none;
    margin: 0 0 0 -20px;
    vertical-align: middle;
  `;
};

export const checkedList = ({ prefix = '& ', isEmail, theme }) => {
  const primaryColor = getProp('main.primaryColor')({ theme }) || '';
  const SVGPrimaryColor = primaryColor.replace('#', '%23');
  if (isEmail)
    return `
      ${prefix} > li {
        display: flex;
        margin: 0 0 0 -20px;
      }
      ${prefix} > li > div > img {
        background: ${primaryColor};
        vertical-align: middle;
        margin-right: 4px;
      }
    `;
  return `
    ${prefix} > li {
      background: url('data:image/svg+xml; utf8,<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path  style="margin: auto; margin-left: 10px;" fill="${SVGPrimaryColor}" d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path></svg>') no-repeat left 5px;
      padding-left: 20px;
      list-style: none;
      margin: 0 0 0 -20px;
      vertical-align: middle;
    }
  `;
};

export const A = styled.a`
  display: inline-block;
`;
export const NoneUnderlineAWrapper = styled.div`
  a {
    text-decoration: none;
  }
`;

export const OL = styled.ol`
  ${addSpaceBetweenLi}
  ${listStyles}
  > li {
    list-style-position: outside;
    text-indent: -4px;
  }
  > li > *:first-child {
    margin-left: 4px;
  }
`;

export const UL = styled.ul`
  ${addSpaceBetweenLi}
  ${listStyles}
  > li {
    list-style-position: outside;
    text-indent: -4px;
  }
  > li > *:first-child {
    margin-left: 4px;
  }
`;
export const ULCheckbox = styled.ul`
  ${addSpaceBetweenLi}
  margin-left: 16px;
  list-style: none;
  > li {
    position: relative;
  }
  > li::marker {
    color: transparent;
  }
`;

export const UncheckedLi = styled.li`
  &::before {
    content: ' ';
    position: absolute;
    left: -19px;
    top: 3px;
    width: 12px;
    height: 12px;
    border: 1px solid black;
    border-radius: 3px;
  }
`;

export const CheckedLi = styled.li`
  ${checkedLi}
`;

export const ULChecked = styled.ul`
  ${addSpaceBetweenLi}
  ${listStyles}
  ${checkedList}
`;
export const Title = styled.h1`
  text-align: center;
  font-weight: ${getProp('title.bold', 'bold', 'normal')};
  font-style: ${getProp('title.italic', 'italic', 'none')};
  text-decoration: ${getProp('title.underline', 'underline', 'none')};
  ${avoidPageBreak}

  font-family: ${getProp('title.fontFamily')};
  color: ${getProp('title.color')};
  font-size: ${getProp('title.fontSize')};
  line-height: ${getProp('title.lineHeight')};
  margin-bottom: ${getProp('title.marginBottom')};
  margin-top: ${getProp('title.marginTop')};
  h1& * {
    font-size: ${getProp('title.fontSize')};
  }
`;

export const LogoImage = styled.img`
  margin-left: auto;
  margin-right: auto;
  display: block;
  max-height: 10cm;
  max-width: 8cm;
`;

const fullImageWidth = ({ mainDoc }) => (mainDoc ? '16.8cm' : '794px');
const fullImageHeight = ({ mainDoc }) => (mainDoc ? '25cm' : '29.7cm');

export const FullPageImage = styled.img`
  width: ${fullImageWidth};
  height: ${fullImageHeight};
  display: block;
  object-fit: contain;
`;

export const H1 = styled.h1`
  font-weight: ${getProp('h1.bold', 'bold', 'normal')};
  text-decoration: ${getProp('h1.underline', 'underline', 'none')};
  font-style: ${getProp('h1.italic', 'italic', 'none')};
  ${avoidPageBreak}
  font-family: ${getProp('h1.fontFamily')};
  color: ${getProp('h1.color')};
  font-size: ${getProp('h1.fontSize')};
  line-height: ${getProp('h1.lineHeight')};
  margin-bottom: ${getProp('h1.marginBottom')};
  margin-top: ${getProp('h1.marginTop')};
  h1& * {
    font-size: ${getProp('h1.fontSize')};
  }
`;

export const H2 = styled.h2`
  font-weight: ${getProp('h2.bold', 'bold', 'normal')};
  text-decoration: ${getProp('h2.underline', 'underline', 'none')};
  font-style: ${getProp('h2.italic', 'italic', 'none')};
  ${avoidPageBreak}
  font-family: ${getProp('h2.fontFamily')};
  color: ${getProp('h2.color')};
  font-size: ${getProp('h2.fontSize')};
  line-height: ${getProp('h2.lineHeight')};
  margin-bottom: ${getProp('h2.marginBottom')};
  margin-top: ${getProp('h2.marginTop')};
  h2& * {
    font-size: ${getProp('h2.fontSize')};
  }
`;

export const H3 = styled.div`
  font-weight: ${getProp('h3.bold', 'bold', 'normal')};
  text-decoration: ${getProp('h3.underline', 'underline', 'none')};
  font-style: ${getProp('h3.italic', 'italic', 'none')};
  ${avoidPageBreak}
  font-family: ${getProp('h3.fontFamily')};
  color: ${getProp('h3.color')};
  font-size: ${getProp('h3.fontSize')};
  line-height: ${getProp('h3.lineHeight')};
  margin-bottom: ${getProp('h3.marginBottom')};
  margin-top: ${getProp('h3.marginTop')};
  h3& * {
    font-size: ${getProp('h3.fontSize')};
  }
`;

// p is the same as the text block
export const TableP = styled.div`
  font-family: ${getProp('textBlock.fontFamily')};
  font-size: ${getProp('textBlock.fontSize')};
  line-height: ${getProp('textBlock.lineHeight')};
  * {
    font-family: ${getProp('textBlock.fontFamily')};
    font-size: ${getProp('textBlock.fontSize')};
    line-height: ${getProp('textBlock.lineHeight')};
  }
`;
export const BaseP = styled(TableP)`
  color: ${getProp('textBlock.color')};
`;
export const B = styled(BaseP)`
  font-weight: bold;
  display: block;
  ${avoidPageBreakDefaultZero}
`;
export const P = styled(BaseP)`
  margin-bottom: ${getProp('textBlock.marginBottom')};
  margin-top: ${getProp('textBlock.marginTop')};
`;

export const Testimonial = styled.div`
  font-style: italic;
  font-family: ${getProp('testimonial.fontFamily')};
  color: ${getProp('testimonial.color')};
  font-size: ${getProp('testimonial.fontSize')};
  line-height: ${getProp('testimonial.lineHeight')};
  margin-bottom: ${getProp('testimonial.marginBottom')};
  margin-top: ${getProp('testimonial.marginTop')};
`;

export const TestimonialSmallText = styled(Testimonial)`
  font-size: ${getProp('testimonialSmallText.fontSize')};
  line-height: ${getProp('testimonialSmallText.lineHeight')};
  margin-bottom: ${getProp('testimonialSmallText.marginBottom')};
  margin-top: ${getProp('testimonialSmallText.marginTop')};
`;

export const Italic = styled.i``;

export const Page = styled.main`
  font-family: ${getProp('main.fontFamily')};
  > section:first-of-type {
    page-break-before: avoid !important;
  }
`;

export const SubTitle = styled(P)`
  text-align: center;
`;

export const BodyPrimary = styled(P)`
  padding: 25px 5px 40px 5px;

  font-family: ${getProp('bodyPrimary.fontFamily')};
  background: ${getProp('bodyPrimary.background')};
  color: ${getProp('bodyPrimary.color')};
  margin-bottom: ${getProp('bodyPrimary.marginBottom')};
  margin-top: ${getProp('bodyPrimary.marginTop')};
  * {
    color: ${getProp('bodyPrimary.color')};
    font-family: ${getProp('bodyPrimary.fontFamily')};
  }
`;

export const BodyPrimaryFooter = styled(P)`
  font-weight: bold;

  font-family: ${getProp('bodyPrimaryFooter.fontFamily')};
  color: ${getProp('bodyPrimaryFooter.color')};
`;

export const Signatures = styled.div`
  display: flex;
  flex-wrap: wrap;
  > div {
    margin-bottom: auto; /* move each signature to the top of <Signatures> (case when one signature one-line and other two-line) */
    width: 40%;
    margin-right: 10%;
    /* border-width: 2px; */
    /* border-style: solid none none none; */
  }
`;
export const EmptySignature = styled.div`
  width: 8cm;
  height: 3cm;
`;
export const Signature = styled.img`
  width: 8cm;
  height: 3cm;
  object-fit: contain;
  object-position: left bottom;
`;

export const Wrapper = styled.section`
  width: 100%;
`;

export const U = styled(P)`
  text-decoration: underline;
`;

export const Li = styled.li`
  * {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
`;

// ----------Header/Footer----------
export const FooterContainer = styled.footer`
  width: 100%;
  font-size: 8pt;
  display: flex;
  padding-left: 1.2cm;
  padding-right: 1.2cm;
  font-family: ${getProp('main.fontFamily')};
`;

export const StyledHeaderComponent = styled.header`
  width: 100%;
  padding-left: 1.85cm;
  padding-right: 1.85cm;
  font-size: 0.5cm;
`;

export const CenterText = styled.section`
  margin-left: auto;
  margin-right: auto;
  font-family: ${getProp('main.fontFamily')};
`;

export const Pagination = styled.section`
  font-size: 8em;
`;

export const HR = styled.div`
  width: 104%;
  margin-top: 35px;
  margin-left: -2%;
  height: 3px;
  background-color: ${getProp('main.primaryColor')};
`;

// ---------TextWithIcon---------

export const TextWithIcon = styled.div`
  display: flex;
  margin-bottom: ${getProp('textBlock.marginBottom')};
  margin-top: ${getProp('textBlock.marginTop')};
`;

export const IconInTextWithIcon = styled.div`
  width: min-content;
  min-width: 12px;
  padding-top: 3px;
`;

export const TextInTextWithIcon = styled.div`
  padding-left: 10px;
  width: 100%;
  > :first-child {
    margin-top: 0 !important;
  }

  > :last-child {
    margin-bottom: 0 !important;
  }
`;

// #region Table
const tdPaddingHeight = '10px';
const tdPaddingWidth = '6px';
// const borderRadius = '16px';
// export const TableRoundedRow = styled(Table)`
//   border-collapse: separate;
//   > tbody > tr,
//   > tr,
//   > thead > tr,
//   > tfoot > rt {
//     border-collapse: unset;
//     > td {
//       border-width: 3px;
//       padding: 0px 12px;
//       > :first-child {
//         margin-top: 10px !important;
//       }

//       > :last-child {
//         margin-bottom: 10px !important;
//         ${avoidPageBreak({ nonBreakingHeight: 12 })}
//       }
//     }
//     > :first-child {
//       border-top-left-radius: ${borderRadius};
//       border-bottom-left-radius: ${borderRadius};
//     }
//     > :last-child {
//       border-top-right-radius: ${borderRadius};
//       border-bottom-right-radius: ${borderRadius};
//     }
//   }
// `;

export const Table = styled.table`
  border-spacing: 0px;
  border-collapse: collapse;
  ${
    '' /* td {
    border: 2px solid;
    border-color: white;
  } */
  }
  width: calc(100% + 4px);
  margin-left: -2px;
  font-size: ${getProp('table.fontSize')};
  margin-top: ${getProp('table.marginTop')};
  ${'' /* margin-bottom: ${getProp('table.marginBottom')}; */}
  $ {
    width: 100%;
    margin-left: 0px;
    margin-bottom: calc(${getProp('table.marginBottom')} - ${tdPaddingHeight}); // KJ-449
  }
`;

export const Td = styled.td`
  width: 100%;
  padding: 0 ${tdPaddingWidth};
  word-wrap: break-word;
  background-clip: padding-box;
  > :first-child {
    margin-top: ${tdPaddingHeight};
  }
  > :last-child {
    margin-bottom: ${tdPaddingHeight};
    ${'' /* ${avoidPageBreak({ nonBreakingHeight: tdPaddingHeight })} */}
  }
  & + & {
    border-left: 2px solid;
    border-color: white;
  }
`;

export const Tr = styled.tr`
  ${'' /* margin-bottom: ${getProp('table.marginBottom')}; */}
  ${'' /* display: flex; */}
  page-break-inside: avoid;
  ${Td} {
    margin-left: -2px;
    border: 2px solid;
    border-color: white;
  }
  width: 100%;
  background: ${getProp('tableContent.background')};
  background-clip: padding-box;
  font-size: ${getProp('table.fontSize')};
  margin-top: ${getProp('table.marginTop')};
  color: ${getProp('tableContent.color')};
  * {
    color: ${getProp('tableContent.color')};
  }
  & + & {
    margin-top: 0px;
    border-top: 0px;
  }
  > ${Td}:first-child {
    width: 70%;
    ${'' /* flex: initial; */}
    ${'' /* text-align: left; */}
  }
  > ${Td}:nth-child(2) {
    vertical-align: top;
    text-align: right;
    ${'' /* flex: 45%; */}
  }
  & & {
    > ${Td}:first-child {
      width: 70.5%;
    }
  }
`;

export const TrTfoot = styled(Tr)`
  font-weight: bold;
  background: ${getProp('tableFooter.background')};
  background-clip: padding-box;
  color: ${getProp('tableFooter.color')};
  * {
    color: ${getProp('tableFooter.color')};
  }
  page-break-inside: avoid;
`;

export const TrThead = styled(Tr)`
  font-weight: bold;
  background: ${getProp('tableHeaderPrimary.background')};
  background-clip: padding-box;
  color: ${getProp('tableHeaderPrimary.color')};
  * {
    color: ${getProp('tableHeaderPrimary.color')};
  }
  page-break-inside: avoid;
`;

export const TrTheadSecondary = styled(TrThead)`
  background: ${getProp('tableHeaderSecondary.background')};
  color: ${getProp('tableHeaderSecondary.color')};
  * {
    color: ${getProp('tableHeaderSecondary.color')};
  }
  background-clip: padding-box;
`;

export const TrLeft = styled(Tr)`
  > ${Td}:first-child {
    ${'' /* flex: 45%; */}
    width: 30%;
    text-align: left;
    font-weight: bold;
    background: ${getProp('tableLeftColumn.background')};
    background-clip: padding-box;
    vertical-align: baseline;
    color: ${getProp('tableLeftColumn.color')};
    * {
      color: ${getProp('tableLeftColumn.color')};
    }
  }
  > ${Td}:nth-child(2) {
    ${'' /* flex: initial; */}
    text-align: left;
  }
`;
export const Glue = styled.div`
  ${avoidPageBreakDefaultZero}
`;
export const HeightTopTable = styled.div`
  ${avoidPageBreakDefaultZero}
  margin-top: ${getProp('table.marginTop')};
  > :first-child {
    margin-top: 0px;
  }
  + ${Tr} {
    margin-top: 0px;
  }
  + ${Table} {
    margin-top: 0px;
  }
`;

export const GlueTopTable = styled(Glue)`
  margin-top: ${getProp('table.marginTop')};
  > :first-child {
    margin-top: 0px;
  }
  & + ${Tr} {
    margin-top: 0px;
    border-top: 0px;
  }
`;

export const GlueFirstNComponents = ({ children, countTop = 4, nonBreakingHeight = 0 }) => {
  const childrenArr = React.Children.toArray(children);
  return (
    <>
      <GlueTopTable nonBreakingHeight={nonBreakingHeight}>{slice(childrenArr, 0, countTop)}</GlueTopTable>
      {slice(childrenArr, countTop)}
    </>
  );
};
// #endregion Table

const Span = styled.span`
  display: inline-block;
`;

const TOCRowLink = styled.a`
  display: inline-block;
  font-family: ${getProp('tableOfContent.fontFamily')};
  color: ${getProp('tableOfContent.color')};
  font-size: ${getProp('tableOfContent.fontSize')};
  text-decoration: none;
`;

const Row = styled.div`
  display: flex;
  font-family: ${getProp('tableOfContent.fontFamily')};
  color: ${getProp('tableOfContent.color')};
  font-size: ${getProp('tableOfContent.fontSize')};
  justify-content: space-between;
`;
export const LessImportantTextStyleWrapper = styled.div`
  font-family: ${getProp('textLessImportant.fontFamily')};
  font-size: ${getProp('textLessImportant.fontSize')};
  color: ${getProp('textLessImportant.color')};
  font-style: italic;
  ${Tr} {
    border-color: ${getProp('textLessImportant.color')};
    border-width: 1px;
    ${Td} {
      border-color: ${getProp('textLessImportant.color')};
      border-width: 1px;
      padding: 2px 7px;
    }
  }
  * {
    td {
      border-color: ${getProp('textLessImportant.color')};
      border-width: 1px;
      padding: 0px 7px;
      > :first-child {
        margin-top: 2px !important;
      }

      > :last-child {
        margin-bottom: 2px !important;
        ${avoidPageBreak({ nonBreakingHeight: 4 })}
      }
    }
    font-family: ${getProp('textLessImportant.fontFamily')};
    font-size: ${getProp('textLessImportant.fontSize')};
    color: ${getProp('textLessImportant.color')};
    font-style: italic;
  }
`;

export const RichTextContainer = styled.span`
  white-space: break-spaces;
`;

export const ContentRow = ({ left, right, level = 0, link, maxWidth }) => {
  const fontWeight = level === 0 ? 'bold' : 'normal';
  const marginTop = level ? 5 : 13;
  return (
    <Row style={{ fontWeight, marginTop, maxWidth }}>
      <TOCRowLink style={{ marginLeft: 40 * level }} href={link}>
        {left}
      </TOCRowLink>
      <Span className="display-none-client" style={{ textAlign: 'right', width: '50px', overflow: 'hidden' }}>
        {right}
      </Span>
    </Row>
  );
};
